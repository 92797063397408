export const PROJECT_CREATED = 'PROJECT_CREATED';
export const PROCESS_INPROGRESS = 'PROCESS_INPROGRESS';
export const PROCESS_COMPLETED = 'PROCESS_COMPLETED';
export const PROCESS_FAILED = 'PROCESS_FAILED';
export const GET_ERRORS = 'GET_ERRORS';

export const NAVIGATE_TO_NEXT_STEP = 'NAVIGATE_TO_NEXT_STEP';
export const LOADING_PEOJECT = 'LOADING_PEOJECT';

export const LOADING_PIPELINE_LOG = 'LOADING_PIPELINE_LOG';
export const GET_PIPELINE_LOG = 'GET_PIPELINE_LOG';
export const PIPELINE_LOG_ERROR = 'PIPELINE_LOG_ERROR';

export const LOADING_PIPELINE_STEPS_LOG = 'LOADING_PIPELINE_STEPS_LOG';
export const GET_PREPARATION_PIPELINE_STEPS_LOG =
  'GET_PREPARATION_PIPELINE_STEPS_LOG';
export const GET_API_PIPELINE_STEPS_LOG = 'GET_API_PIPELINE_STEPS_LOG';
export const PIPELINE_STEPS_LOG_ERROR = 'PIPELINE_STEPS_LOG_ERROR';

export const LOADING_TRIGGER_API = 'LOADING_TRIGGER_API';
export const TRIGGER_API = 'TRIGGER_API';

export const SET_NAVIGATION_TAB = 'SET_NAVIGATION_TAB';

export const LOADING_PREPARATION_PIPELINE_LOG =
  'LOADING_PREPARATION_PIPELINE_LOG';
export const GET_PREPARATION_PIPELINE_LOG = 'GET_PREPARATION_PIPELINE_LOG';
export const LOADING_APICALL_PIPELINE_LOG = 'LOADING_APICALL_PIPELINE_LOG';
export const GET_APICALL_PIPELINE_LOG = 'GET_APICALL_PIPELINE_LOG';
